<template>
  <div>
    <v-row>
      <v-col cols="12">
        <base-card>
          <v-card-text class="v-card__text--dense">
            <div class="d-flex justify-space-between align-center">
              <div class="card-title ma-0 text-capitalize ">
                {{ $t(`app.sections.${currentRouteName}`) }}
              </div>
              <v-breadcrumbs :items="routePath">
                <template #divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
            </div>
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <today-statistics :display-download-amount="downloadSumIsAvailable"></today-statistics>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <div class="d-flex mb-2">
          <div class="ma-0 body-2 text--disabled">
            <span class="text-caption mt-2 text-uppercase text--disabled">{{ t("chart.title") }}</span>
          </div>
        </div>
        <v-card height="100%">
          <v-card-title class="justify-space-between align-start">
            <div>
              <v-select
                v-model="chartType"
                :items="chartTypes"
                :label="t('chart.select.label')"
                outlined
                dense
              ></v-select>
            </div>
            <v-spacer />
            <filters-block @update="onFilterUpdate"></filters-block>
          </v-card-title>
          <v-card-text class="ps-4 pe-4">
            <chart :raw-data="items" :values="chartType"></chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="12">
        <v-card>
          <v-card-title class="justify-space-between">
            <div class="card-title ma-0 body-2 text--disabled text-uppercase">{{ t("table.title") }}</div>
            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="total"
              :items-per-page="options.itemsPerPage"
              :loading="loading"
              :footer-props="footerProps"
              show-expand
              :single-expand="true"
              :expanded.sync="expanded"
              @update:options="onOptionsUpdate"
            >
              <template slot="body.append">
                <tr v-if="additional && !$vuetify.breakpoint.xs" class="font-weight-bold">
                  <td>{{ $t("common.tables.total") }}</td>
                  <td>{{ additional.download_count }}</td>
                  <td>
                    <template v-if="downloadSumIsAvailable">{{ additional.download_amount }}</template>
                    <template v-else>{{ additional.premium_avg }}</template>
                  </td>
                  <td>{{ additional.premium_count }}</td>
                  <td>{{ additional.premium_amount }}</td>
                  <td>{{ additional.copies_amount }}</td>
                  <td>{{ additional.gold_amount }}</td>
                  <td>{{ additional.total_amount }}</td>
                  <td>{{ additional.avg_download_amount }}</td>
                </tr>
              </template>

              <template #expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-5">
                  <country-details-table :date="item.id" :group="filters.group"></country-details-table>
                </td>
              </template>
            </v-data-table>
            <div class="body-2" v-html="t('notes.copies', { copies_url: 'files-copies' })"></div>
            <div class="body-2" v-html="t('notes.avg_downloads')"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dataTable from "@/views/mixins/dataTable";
import todayStatistics from "./TodayStatistics";
import filtersBlock from "@/views/pages/earnings/Filters";
import chart from "./Chart";
import countryDetailsTable from "./CountryDetailsTable";

/**
 * Страница с детальной статистикой
 */
export default {
  components: {
    todayStatistics,
    filtersBlock,
    chart,
    countryDetailsTable
  },
  metaInfo() {
    return {
      title: this.$t("titles.stats_detailed")
    };
  },
  mixins: [dataTable],
  data() {
    return {
      // Строки с открытыми подчиненными таблицами
      expanded: [],
      chartType: "downloads",
      filters: {
        from: null,
        to: null,
        group: "day"
      }
    };
  },

  computed: {
    ...mapGetters("app", ["appDomain", "appTitle"]),

    /**
     * Apptype статистики
     */
    appType() {
      return this.$route.params.app.toLowerCase();
    },

    /**
     * Доступность показа заработка на скачиваниях
     */
    downloadSumIsAvailable() {
      return this.appType == "fd1";
    },

    /**
     * Имя текущей секции
     */
    currentRouteName() {
      return this.$route.name;
    },

    /**
     * Варианты графика
     */
    chartTypes() {
      return [
        { text: this.t("table.fields.downloads"), value: "downloads" },
        { text: this.t("table.fields.sales"), value: "sales" },
        { text: this.t("table.fields.total"), value: "total" }
      ];
    },

    /**
     * Колонки таблицы
     */
    headers() {
      let columnAfterDownloadCount = {
        text: this.t("table.fields.sum"),
        value: "download_amount",
        sortable: true
      };

      if (this.downloadSumIsAvailable == false) {
        columnAfterDownloadCount = {
          text: this.t("table.fields.avg_sales"),
          value: "premium_avg",
          sortable: true
        };
      }

      let columns = [
        {
          text: this.t("table.fields.date"),
          value: "id",
          sortable: true,
          width: "10em"
        },
        {
          text: this.t("table.fields.downloads"),
          value: "download_count",
          sortable: true
        },
        columnAfterDownloadCount,
        {
          text: this.t("table.fields.sales"),
          value: "premium_count",
          sortable: true
        },
        {
          text: this.t("table.fields.sum"),
          value: "premium_amount",
          sortable: true
        },
        {
          text: this.t("table.fields.copies"),
          value: "copies_amount",
          sortable: true
        },
        {
          text: this.t("table.fields.gold"),
          value: "gold_amount",
          sortable: true
        },
        {
          text: this.t("table.fields.total"),
          value: "total_amount",
          sortable: true
        },
        {
          text: this.t("table.fields.avg_download_amount"),
          value: "avg_download_amount",
          sortable: true
        }
      ];

      columns.push({ text: "", value: "data-table-expand" });
      return columns;
    },

    /**
     * Данные для хлебных крошек
     */
    routePath() {
      return [
        {
          text: this.$t("app.sections.dashboard"),
          disabled: false,
          href: "/"
        },
        {
          text: this.appTitle(this.appType),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("app.sections.stats.detailed"),
          disabled: true,
          href: "/"
        }
      ];
    }
  },

  watch: {
    /**
     * Если изменился apptype
     */
    appType() {
      this.clearOptions();
      this.loadData();
    }
  },

  mounted() {},

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.statistics.detailed." + key, params);
    },

    /**
     * Сброс условий к значениям по-умолчанию
     */
    clearOptions() {
      this.options.page = 1;
      this.options.itemsPerPage = 25;
      this.options.sortBy = ["id"];
      this.options.sortDesc = [true];
      this.expanded = [];
    },

    /**
     * Формирование параметров для запроса данных
     */
    getLoadingParams() {
      let data = {
        app: this.appType
      };

      // Обработка фильтров
      ["from", "to", "group"].forEach(name => {
        if (this.filters[name]) {
          data[name] = this.filters[name];
        }
      });

      return Object.assign(this.getBaseParams(), data);
    },

    /**
     * Загрузка данных по датам
     */
    loadData() {
      this.loading = true;
      this.$store
        .dispatch("statistics/loadDetailedBaseTableData", this.getLoadingParams())
        .then(this.processDataLoadResponse)
        .catch(this.catchDataLoadResponse);
    },

    /**
     * Реакция на изменение фильтра
     */
    onFilterUpdate(data) {
      this.filters = data;
      if (this.options.page != 1) {
        this.oldPageValue = this.options.page = 1;
      } else {
        this.loadData();
      }
    }
  }
};
</script>
