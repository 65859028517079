<template>
  <apexchart type="area" height="350" :options="options" :series="series" />
</template>
<script>
/**
 * График для детальной статистики
 */
export default {
  props: {
    values: {
      type: String,
      default: "total"
    },
    rawData: {
      type: Array,
      default: new Array()
    }
  },
  data() {
    return {};
  },

  computed: {
    options() {
      let mapping = {
        downloads: "#4CA8FF",
        sales: "#F14004",
        total: "#43A047"
      };
      let color = mapping[this.values];

      return {
        chart: {
          type: "area",
          toolbar: {
            show: false
          }
        },
        colors: [color],
        stroke: {
          curve: "smooth",
          width: [6]
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: [5],
          hover: { sizeOffset: 2 }
        },
        title: {
          text: ""
        },
        xaxis: {
          type: "category"
        },
        yaxis: []
      };
    },

    series() {
      let result = [];

      // Выбор варианта графика
      let mapping = {
        downloads: "download_count",
        sales: "premium_count",
        total: "total_amount"
      };

      let key = mapping[this.values];

      this.rawData.forEach(row => {
        result.push({
          x: row.id,
          y: row[key]
        });
      });

      return [
        {
          name: this.t(`table.fields.${this.values}`),
          data: result.sort(this.compareNodes)
        }
      ];
    }
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.statistics.detailed." + key, params);
    },

    /**
     * Сортировка значений узлов графика по координате "y" (дате)
     * @param a
     * @param b
     * @return {number}
     */
    compareNodes(a, b) {
      if (a.x < b.x) {
        return -1;
      }
      if (a.x > b.x) {
        return 1;
      }
      return 0;
    }
  }
};
</script>
