<template>
  <div>
    <div class="d-flex mb-2">
      <div class="ma-0 body-2 text--disabled">
        <span class="text-caption mt-2 text-uppercase text--disabled">{{ t("today_stat.title") }}</span>
        {{ t("today_stat.sub_title") }}
      </div>
      <v-spacer />
      <v-chip color="grey lighten-5" @click="loadTodayData">
        {{ t("today_stat.refresh") }}
        <v-avatar right> <v-icon color="blue">mdi-reload</v-icon> </v-avatar>
      </v-chip>
    </div>
    <base-card>
      <v-card-text class="pt-0 pb-0">
        <v-row align="center">
          <v-col cols="12">
            <v-row>
              <v-col class="d-flex flex-center align-center " :cols="columnSize">
                <div class>
                  <p class="mb-0  me-4">
                    {{ t("table.fields.downloads") }}
                  </p>
                </div>
                <h1 class="text-h4 font-weight-thin mb-0">
                  <template v-if="todayData">
                    {{ todayData.download_count }}
                    <span v-if="displayDownloadAmount" class="text-h6 font-weight-thin"
                      >(${{ todayData.download_amount }})</span
                    >
                  </template>
                  <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
                </h1>
              </v-col>
              <v-col class="d-flex flex-center align-center " :cols="columnSize">
                <div class>
                  <p class="mb-0  me-4">
                    {{ t("table.fields.sales") }}
                  </p>
                </div>
                <h4 class="text-h4 font-weight-thin mb-0">
                  <template v-if="todayData">
                    {{ todayData.premium_count }}
                    <span class="text-h6 font-weight-thin">(${{ todayData.premium_amount }})</span>
                  </template>
                  <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
                </h4>
              </v-col>
              <v-col class="d-flex flex-center align-center " :cols="columnSize">
                <div class>
                  <p class="mb-0  me-4">
                    <a href="#" class="amber--text text--darken-4">
                      {{ t("today_stat.gold") }}
                    </a>
                  </p>
                </div>
                <h4 class="text-h4 font-weight-thin mb-0">
                  <template v-if="todayData">${{ todayData.gold_amount }}</template>
                  <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
                </h4>
              </v-col>
              <v-col class="d-flex flex-center align-center " :cols="columnSize">
                <div class>
                  <p class="mb-0  me-4 font-weight-bold">
                    {{ t("table.fields.total") }}
                  </p>
                </div>
                <h4 class="text-h4 font-weight-thin mb-0">
                  <template v-if="todayData">${{ todayData.total_amount }}</template>
                  <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
                </h4>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </base-card>
  </div>
</template>

<script>
/**
 * Блок с детальной систаистикой за сегодня
 */
export default {
  props: {
    displayDownloadAmount: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      todayData: null
    };
  },

  computed: {
    /**
     * Apptype статистики
     */
    appType() {
      return this.$route.params.app;
    },

    /**
     * Колонки с данными
     */
    columnSize() {
      const { xs, sm } = this.$vuetify.breakpoint;
      if (xs) {
        return 12;
      }
      if (sm) {
        return 6;
      }
      return 3;
    }
  },

  watch: {
    /**
     * Если изменился apptype
     */
    appType() {
      this.loadTodayData();
    }
  },

  mounted() {
    this.loadTodayData();
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.statistics.detailed." + key, params);
    },

    /**
     * Загрузка данных за сегодня
     */
    loadTodayData() {
      this.todayData = null;
      this.$store.dispatch("statistics/loadDetailedTodayData", { app: this.appType }).then(this.processTodayData);
    },

    /**
     * Обработка данных за сегодня
     * @param response
     */
    processTodayData(response) {
      this.todayData = response.data.data;
    }
  }
};
</script>
