<template>
  <div>
    <h6 class="text-caption text--disabled tracking-widest mt-2">
      {{ t("sub_table.title", { date }) }}
    </h6>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :loading="loading"
      hide-default-footer
      class="elevation-1 no-full-width"
    >
      <template #item.id="{ item }">
        {{ t(`sub_table.countries.${item.id}`) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
/**
 * Детальная статистика по странам
 */
export default {
  props: {
    date: null,
    group: { type: String, default: "day" }
  },
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      options: {
        sortBy: ["id"],
        sortDesc: [false]
      }
    };
  },
  computed: {
    /**
     * Apptype статистики
     */
    appType() {
      return this.$route.params.app.toLowerCase();
    },

    /**
     * Колонки таблицы
     */
    headers() {
      let columns = [
        {
          text: this.t("sub_table.fields.countries"),
          value: "id",
          sortable: true,
          width: "20em"
        },
        {
          text: this.t("table.fields.downloads"),
          value: "download_count",
          sortable: true
        },
        {
          text: this.t("sub_table.fields.sum"),
          value: "download_amount",
          sortable: true
        },
        {
          text: this.t("table.fields.sales"),
          value: "premium_count",
          sortable: true
        },
        {
          text: this.t("sub_table.fields.sum"),
          value: "premium_amount",
          sortable: true
        },
        {
          text: this.t("table.fields.total"),
          value: "total_amount",
          sortable: true
        }
      ];

      columns.push({ text: "", value: "data-table-expand" });
      return columns;
    }
  },

  watch: {
    date() {
      this.loadData();
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.statistics.detailed." + key, params);
    },

    /**
     * Формирование параметров для запроса данных
     */
    getLoadingParams() {
      let data = {
        app: this.appType,
        date: this.date,
        group: this.group
      };

      return data;
    },

    /**
     * Загрузка данных по датам
     */
    loadData() {
      this.loading = true;
      this.$store
        .dispatch("statistics/loadDetailedCountriesTableData", this.getLoadingParams())
        .then(this.processDataLoadResponse)
        .catch(this.catchDataLoadResponse);
    },

    /**
     * Обработка данных от сервера
     */
    processDataLoadResponse(response) {
      this.items = response.data.data;
      this.loading = false;
    },

    /**
     * Обработка ошибки при загрузке данных
     */
    catchDataLoadResponse() {
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
/**
 * Таблица не должна занимать всю ширину
 */
.no-full-width {
  max-width: max-content;
  table {
    width: auto;
  }
}
</style>
